import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Header from "components/Header";
import { Send } from "@mui/icons-material";
import toast from "react-hot-toast";

const UpdateNews = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState({});
  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(`${server}/news/get-single-news`, {
          params: {
            _id: id,
          },
        });
        setNewsData({
          _id: data.news._id,

          titleBn: data.news.title.bn,
          titleEn: data.news.title.en,
          link: data.news.link,
        });
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      newsData.titleBn === "" ||
      newsData.titleEn === "" ||
      newsData.link === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: newsData._id,

        title: {
          bn: newsData.titleBn,
          en: newsData.titleEn,
        },
        link: newsData.link,
      };

      axios
        .put(`${server}/news/edit-news`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNewsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="NEWS" subTitle="List of News" />
      <Box>
        {newsData._id && (
          <form onSubmit={handleSubmit}>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>News Title</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="titleBn"
                    value={newsData?.titleBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="titleEn"
                    value={newsData?.titleEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
                Enter the link of PDF
              </Typography>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="link"
                  value={newsData?.link}
                  onChange={handleChange}
                />
              </Grid>
            </Box>
            <Button
              sx={{ marginTop: 3 }}
              type="submit"
              variant="contained"
              startIcon={<Send />}
            >
              {loading ? "Posting..." : "Post"}
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
};

export default UpdateNews;
