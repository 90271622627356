import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  Input,
  InputLabel,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { CloudUploadRounded, Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-hot-toast";
import { server } from "../../server";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};

const Banner = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetCustomersQuery();
  const [prevData, setPrevData] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textField1Value, setTextField1Value] = useState("");
  const [textField2Value, setTextField2Value] = useState("");
  const [textField3Value, setTextField3Value] = useState("");
  const [textField4Value, setTextField4Value] = useState("");

  const handleTextField1Change = (event) => {
    setTextField1Value(event.target.value);
  };

  const handleTextField2Change = (event) => {
    setTextField2Value(event.target.value);
  };

  const handleTextField3Change = (event) => {
    setTextField3Value(event.target.value);
  };

  const handleTextField4Change = (event) => {
    setTextField4Value(event.target.value);
  };



  useEffect(() => {
    axios
      .get(`${server}/banner/get-banner`)
      .then((response) => {
        setPrevData(response.data.banner[0]);
        setTextField3Value(response.data.banner[0].marqeue?.bn)
        setTextField4Value(response.data.banner[0].marqeue?.en)
        setTextField1Value(response.data.banner[0].title?.bn)
        setTextField2Value(response.data.banner[0].title?.en)
      })
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  }, [ images]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageDelete = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleClick = () => {
    setLoading(true);

    const title = {
      bn: textField1Value !== "" ? textField1Value : prevData?.title?.bn,
      en: textField2Value !== "" ? textField2Value : prevData?.title?.en,
    };

    const marqeue = {
      bn: textField3Value !== "" ? textField3Value : prevData?.marqeue?.bn,
      en: textField4Value !== "" ? textField4Value : prevData?.marqeue?.en,
    };

    const data = {
      title,
      images,
      marqeue,
    };

    if (
      textField1Value === "" &&
      textField2Value == "" &&
      textField3Value === "" &&
      textField4Value == "" &&
      data.images.length === 0
    ) {
      toast.error("Data is empty!");
    } else {
      axios
        .post(`${server}/banner/create`, data)
        .then((response) => {
          toast.success("Successfully updated.");
          setTextField1Value("");
          setTextField2Value("");
          setTextField3Value("");
          setTextField4Value("");
          setImages([]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error creating post:", error);
          setLoading(false);
        });
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="BANNER" subTitle="List of Banners" />
      <Box>
        <Box sx={{ marginTop: 3 }}>
          <Typography sx={{ marginBottom: 1 }}>Banner Title</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="বাংলাতে লিখুন"
                variant="outlined"
                fullWidth
                value={textField1Value}
                onChange={handleTextField1Change}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Enter the text in English"
                variant="outlined"
                fullWidth
                value={textField2Value}
                onChange={handleTextField2Change}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
            Upload Images
          </Typography>
          <input
            type="file"
            name=""
            id="upload"
            style={{ display: "none" }}
            multiple
            onChange={handleImageChange}
          />
          <Grid container spacing={2}>
            <Grid item>
              <label htmlFor="upload">
                <IconButton component="span">
                  <AddCircleOutlineIcon color="primary" fontSize="large" />
                </IconButton>
              </label>
            </Grid>
            {images.map((image, index) => (
              <Grid item key={image}>
                <div style={{ position: "relative" }}>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleImageDelete(index)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <img
                    src={image}
                    alt=""
                    style={{
                      height: 120,
                      width: 120,
                      objectFit: "cover",
                      margin: 2,
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <Typography sx={{ marginBottom: 1 }}>Breaking News</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="বাংলাতে লিখুন"
                variant="outlined"
                fullWidth
                value={textField3Value}
                onChange={handleTextField3Change}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Enter the text in English"
                variant="outlined"
                fullWidth
                value={textField4Value}
                onChange={handleTextField4Change}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          sx={{ marginTop: 3 }}
          onClick={handleClick}
          variant="contained"
          startIcon={<CloudUploadRounded />}
        >
          {loading ? "Uploading..." : "Update"}
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
