import React from "react";
import Header from "components/Header";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetDashboardQuery } from "state/api";

const Dashboard = () => {
  console.log("I am from BTCL")

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      <p>Welcome, Admin!</p>
    </Box>
  );
};

export default Dashboard;
