import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const DeleteDialog = ({ open, setOpen, handleDelete }) => {

  const deleteHandler = async () => {
    await handleDelete();
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="responsive-dialog-title" sx={{ minWidth: 350 }}>
        {"Are you sure to delete?"}
      </DialogTitle>

      <DialogActions sx={{ marginTop: 6 }}>
        <Button
          autoFocus
          onClick={() => setOpen(false)}
          variant="outlined"
          sx={{ color: "white" }}
        >
          No
        </Button>
        <Button onClick={deleteHandler} color="error" variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
