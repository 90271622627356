import React, { useMemo } from "react";
import { Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import { useGetOverallSalesQuery } from "state/api";
import "react-datepicker/dist/react-datepicker.css";
import { CloudUploadRounded } from "@mui/icons-material";

const Monthly = () => {

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CONTACT US" subTitle="Update your contact information" />
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE NAME
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE ADDRESS
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE OFFICE PHONE NUMBER
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE RECEITIONIST'S NUMBER
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE PHONE NUMBER OF OFFICE INFORMATION
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE PHONE NUMBER OF COVID-19 TEST'S RESULT
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE FAX NUMBER
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the text in English"
              variant="outlined"
              fullWidth

            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>

        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the E-mail"
              variant="outlined"
              fullWidth

            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the website's URL"
              variant="outlined"
              fullWidth


            />
          </Grid>


        </Grid>
        <Button
          sx={{ marginTop: 2, marginBottom: 2 }}
          variant="contained"
          startIcon={<CloudUploadRounded />}
        >
          Update
        </Button>
      </Box>


    </Box>
  );
};

export default Monthly;
