import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import { useGetAdminsQuery, useGetCustomersQuery } from "state/api";
import axios from "axios";
import { server } from "server";
import { toast } from "react-hot-toast";
import { CloudUploadRounded, Delete, Edit, Photo } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowGallery from "./ShowGallery";

const PhotoGallery = () => {
  const theme = useTheme();
  const { isLoading } = useGetCustomersQuery();
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [textField1Value, setTextField1Value] = useState("");
  const [textField2Value, setTextField2Value] = useState("");
  const [textField3Value, setTextField3Value] = useState("");
  const [textField4Value, setTextField4Value] = useState("");
  const [textField5Value, setTextField5Value] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteData,setDeleteData]=useState(0)

  const handleTextField1Change = (event) => {
    setTextField1Value(event.target.value);
  };

  const handleTextField2Change = (event) => {
    setTextField2Value(event.target.value);
  };
  const handleTextField3Change = (event) => {
    setTextField3Value(event.target.value);
  };

  const handleTextField4Change = (event) => {
    setTextField4Value(event.target.value);
  };
  const handleTextField5Change = (event) => {
    setTextField5Value(event.target.value);
  };

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long" };
    const date = new Date(inputDate);
    return date.toLocaleDateString(undefined, options);
  }
  const handleThumbnailChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setThumbnail(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageDelete = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleClick = () => {
    setLoading(true);

    const data = {
      title: {
        bn: textField1Value,
        en: textField2Value,
      },
      description: {
        bn: textField3Value,
        en: textField4Value,
      },
      thumbnail,
      images,
      date: textField5Value,
      date2: formatDate(textField5Value),
    };

    axios
      .post(`${server}/gallery/create`, data)
      .then((response) => {
        toast.success("Successfully Added.");
        setTextField1Value("");
        setTextField2Value("");
        setTextField3Value("");
        setTextField4Value("");
        setTextField5Value("");
        setThumbnail("");
        setImages([]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${server}/gallery/delete-gallery/${id}`)
      .then((response) => {
        toast.success("Successfully Deleted.");
        setDeleteData(deleteData+1)
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${server}/gallery/get-gallery`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  }, [
   deleteData,loading
  ]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PHOTO GALLERY" subTitle="Managing photo gallery" />
      <Box
        sx={{
          marginTop: 2,
        }}
      >
        <Typography
          sx={{
            marginBottom: 1,
          }}
        >
          ENTER THE TITLE
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth
              value={textField1Value}
              onChange={handleTextField1Change}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Write in English"
              variant="outlined"
              fullWidth
              value={textField2Value}
              onChange={handleTextField2Change}
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            marginBottom: 1,
            marginTop: 3,
          }}
        >
          ENTER THE DESCRIPTION
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="বাংলাতে লিখুন"
              variant="outlined"
              fullWidth
              value={textField3Value}
              onChange={handleTextField3Change}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Write in English"
              variant="outlined"
              fullWidth
              value={textField4Value}
              onChange={handleTextField4Change}
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            marginTop: 3,
          }}
        >
          CHOOSE A DATE
        </Typography>

        <TextField
          sx={{
            width: "100%",
          }}
          id="outlined-basic"
          variant="outlined"
          type="date"
          value={textField5Value}
          onChange={handleTextField5Change}
        />

        <Typography
          sx={{
            marginBottom: 1,
            marginTop: 3,
          }}
        >
          CHOOSE A THUMBNAIL PHOTO
        </Typography>
        <Button
          variant="contained"
          component="label"
          sx={{}}
          startIcon={<Photo />}
        >
          <input onChange={handleThumbnailChange} type="file" />
        </Button>

        <Box sx={{ marginTop: 3 }}>
          <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
            UPLOAD IMAGES
          </Typography>
          <input
            type="file"
            name=""
            id="upload"
            style={{ display: "none" }}
            multiple
            onChange={handleImageChange}
          />
          <Grid container spacing={2}>
            <Grid item>
              <label htmlFor="upload">
                <IconButton component="span">
                  <AddCircleOutlineIcon color="primary" fontSize="large" />
                </IconButton>
              </label>
            </Grid>
            {images.map((image, index) => (
              <Grid item key={image}>
                <div style={{ position: "relative" }}>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleImageDelete(index)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 1,
                      color:"#ff0000"
                    }}
                  >
                    <DeleteIcon color="#ff0000" />
                  </IconButton>
                  <img
                    src={image}
                    alt=""
                    style={{
                      height: 120,
                      width: 120,
                      objectFit: "cover",
                      margin: 2,
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          sx={{ marginTop: 3 }}
          onClick={handleClick}
          variant="contained"
          disabled={loading}
          startIcon={<CloudUploadRounded />}
        >
          {loading ? "Uploading..." : "Update"}
        </Button>
      </Box>

      <ShowGallery galleryData={data.gallery} handleDelete={handleDelete} />
    </Box>
  );
};

export default PhotoGallery;
