import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import {
  Dashboard,
  Layout,
  Geography,
  Overview,
  NOC,
  Monthly,
  Breakdown,
  Banner,
  PhotoGallery,
} from "scenes";
import { Toaster } from "react-hot-toast";
import Users from "scenes/users/Users";
import Login from "components/Login";
import VideoGallery from "scenes/videoGallery/videoGallery";
import SideBar from "scenes/sidebar/SideBar";
import Staff from "scenes/staff/Staff";
import UpdateStaff from "scenes/staff/UpdateStaff";
import News from "scenes/news/News";
import Journal from "scenes/journal/Journal";
import UpdateNOC from "scenes/noc/UpdateNOC";
import UpdateNews from "scenes/news/UpdateNews";
import UpdateJournal from "scenes/journal/UpdateJournal";
import UpdateGallery from "scenes/photoGallery/UpdateGallery";
import UpdateVideoGallery from "scenes/videoGallery/UpdateVideoGallery";
import UpdateSideBar from "scenes/sidebar/UpdateSideBar";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/staff_info" element={<Staff />} />
              <Route path="/update_staff_info/:id" element={<UpdateStaff />} />

              <Route path="/banner" element={<Banner />} />
              <Route path="/users" element={<Users />} />
              <Route path="/sidebar" element={<SideBar />} />
              <Route path="/update_sidebar/:id" element={<UpdateSideBar />} />
              <Route path="/news" element={<News />} />
              <Route path="/update_news/:id" element={<UpdateNews />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/noc" element={<NOC />} />
              <Route path="/update_noc/:id" element={<UpdateNOC />} />
              <Route path="/contact_us" element={<Monthly />} />
              <Route path="/ict" element={<Monthly />} />
              <Route path="/research" element={<Monthly />} />
              <Route path="/journal" element={<Journal />} />
              <Route path="/update_journal/:id" element={<UpdateJournal />} />
              <Route path="/notice" element={<Monthly />} />
              <Route path="/investigation_price" element={<Breakdown />} />
              <Route path="/photo_gallery" element={<PhotoGallery />} />
              <Route path="/update_photo_gallery/:id" element={<UpdateGallery />} />
              <Route path="/video_gallery" element={<VideoGallery />} />
              <Route path="/update_video_gallery/:id" element={<UpdateVideoGallery />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
      <Toaster position="top-center" />
    </div>
  );
}

export default App;
