import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DeleteDialog from "components/DeleteDialog";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../server";

const ShowStaff = ({ staffData, handleDelete }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setFilteredData(
      staffData?.map((item) => ({
        id: item._id,
        idNo: item.idNo,
        serial: item.serial,
        name: item.name.en,
        designation: item.designation.en,
        phone: item.phoneNumber.en,
        email: item.email,
        bloodGroup: item.bloodGroup,
      })) || []
    );
  }, [staffData]);

  const handleFront = async (id, name) => {
    setLoading(true);
    try {
      axios
        .post(`${server}/idcard/idcard-front/${id}`)
        .then(() =>
          axios.get(`${server}/idcard/get-front`, { responseType: "blob" })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, `${name}_front.pdf`);
        });

      console.log("hi");
      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
    }
  };

  const handleBack = async (id, name) => {
    setLoading(true);
    try {
      axios
        .post(`${server}/idcard/idcard-back/${id}`)
        .then(() =>
          axios.get(`${server}/idcard/get-back`, { responseType: "blob" })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, `${name}_back.pdf`);
        });

      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
    }
  };

  const columns = [
    { field: "id", headerName: "", width: 120, hide: true },
    { field: "idNo", headerName: "ID", width: 120 },
    {
      field: "serial",
      headerName: "Serial",
      width: 80,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
    },
    {
      field: "bloodGroup",
      headerName: "Blood Goroup",
      width: 175,
    },
    {
      field: "phone",
      headerName: "Phone No.",
      width: 175,
    },
    {
      field: "email",
      headerName: "Email",
      width: 175,
    },
    {
      field: "generateId",
      headerName: "Generate ID Card",
      width: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            {loading ? (
              <>
                <Typography sx={{}}>Generating...</Typography>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={() => handleFront(params.id, params.row.name)}
                >
                  Front
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleBack(params.id, params.row.name)}
                >
                  Back
                </Button>
              </>
            )}
          </Box>
        );
      },
    },

    {
      field: "action",
      minWidth: 120,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setOpen(true);
                setSelected(params.id);
              }}
            >
              <DeleteOutline color="error" size={20} />
            </IconButton>
            <IconButton>
              <EditIcon
                color="info"
                size={20}
                onClick={() => navigate(`/update_staff_info/${params.id}`)}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setTimeout(() => {
      if (!value) {
        setFilteredData(
          staffData?.map((item) => ({
            id: item._id,
            idNo: item.idNo,
            serial: item.serial,
            name: item.name.en,
            designation: item.designation.en,
            phone: item.phoneNumber.en,
            email: item.email,
            bloodGroup: item.bloodGroup,
          }))
        );
        return;
      }

      setFilteredData(
        filteredData.filter(
          (item) =>
            item?.serial === Number(value) ||
            item?.name?.toLowerCase().includes(value.toLowerCase())
        )
      );
    }, 500);
  };

  return (
    <>
      <Box sx={{ paddingBottom: 7 }}>
        <Stack justifyContent="flex-end" direction="row">
          <TextField
            label="Search"
            variant="outlined"
            onChange={handleSearch}
            value={search}
          />
        </Stack>
        {filteredData && (
          <Box sx={{ height: 700, width: "100%", marginTop: 7 }}>
            <DataGrid
              rows={filteredData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              rowsPerPageOptions={[10, 25, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selected)}
      />
    </>
  );
};

export default ShowStaff;
