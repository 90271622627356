import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery } from "state/api";
import { DeleteOutline } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
const ShowGallery = ({ galleryData, handleDelete }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const columns = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
    },
    {
      field: "delete",
      minWidth: 120,
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                setOpen(true);
                setSelected(params.id);
              }}
              style={{ color: "red" }}
            >
              <DeleteOutline size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "edit",
      minWidth: 120,
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <EditIcon
                color="info"
                size={20}
                onClick={() => navigate(`/update_photo_gallery/${params.id}`)}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const rows = [];

  galleryData &&
    galleryData.forEach((item) => {
      rows.push({
        id: item._id,
        title: item.title.bn,
        description: item.description.bn,
      });
    });

  return (
    <>
      <Box sx={{ paddingBottom: 7 }}>
        <Box sx={{ height: 300, width: "100%", marginTop: 7 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pphoneSize: 5,
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            pphoneSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
      <DeleteDialog
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selected)}
      />
    </>
  );
};

export default ShowGallery;
