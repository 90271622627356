import { PersonAdd, Photo } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Header from "components/Header";
import { server } from "../../server";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const UpdateStaff = () => {
  const { id } = useParams();
  const [refetch, setRefetch] = useState(false);
  const [newAvatar, setNewAvatar] = useState("");
  const [loading, setLoading] = useState(false);
  const [bloodGroup, setBloodGroup] = useState("");
  const [staffData, setStaffData] = useState({});
  console.log(staffData, "staffdara");
  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(
          `${server}/staffinfo/get-single-staffinfo`,
          {
            params: {
              _id: id,
            },
          }
        );
        setStaffData({
          _id: data.staffInfo._id,
          idNo: data.staffInfo.idNo,
          serial: data.staffInfo.serial,
          designationBn: data.staffInfo.designation.bn,
          designationEn: data.staffInfo.designation.en,
          nameBn: data.staffInfo.name.bn,
          nameEn: data.staffInfo.name.en,
          phoneNumBn: data.staffInfo.phoneNumber.bn,
          phoneNumEn: data.staffInfo.phoneNumber.en,
          email: data.staffInfo.email,
          bloodGroup: data.staffInfo.bloodGroup,
          avatar: data.staffInfo.avatar,
        });
        console.log(data, "sss", staffData);
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);

  const handleBloodGroupChange = (event) => {
    setBloodGroup(event.target.value);
  };
  const handleAvatarChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setNewAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  const handleClick = () => {
    setLoading(true);

    if (
      staffData.serial === 0 ||
      staffData.designationBn === "" ||
      staffData.designationEn === "" ||
      staffData.nameBn === "" ||
      staffData.nameEn === "" ||
      staffData.phoneNumBn === "" ||
      staffData.phoneNumEn === "" ||
      staffData.idNo === "" ||
      staffData.email === "" ||
      
      staffData.bloodGroup === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id:staffData._id,
        designation: {
          bn: staffData.designationBn,
          en: staffData.designationEn,
        },
        name: {
          bn: staffData.nameBn,
          en: staffData.nameEn,
        },
        phoneNumber: {
          bn: staffData.phoneNumBn,
          en: staffData.phoneNumEn,
        },
        email: staffData.email,
        avatar:staffData.avatar,
        newAvatar,
        serial: staffData.serial,
        bloodGroup: staffData.bloodGroup,
        idNo: staffData.idNo,
      };

      axios
        .put(`${server}/staffInfo/edit-staffinfo`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name, value, staffData);
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="UPDATE STAFF INFORMATION"
        subTitle="Update Information for office staff"
      />
      {staffData?._id && (
        <form>
          <Box sx={{ marginTop: 3 }}>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>নাম/Name</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="nameBn"
                    value={staffData.nameBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="nameEn"
                    value={staffData.nameEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: 1, marginTop: 3 }}>
                পদবী/Designation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="designationBn"
                    value={staffData?.designationBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="designationEn"
                    value={staffData?.designationEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Blood Group</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Blood Group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={staffData?.bloodGroup}
                      label="Blood Group"
                      name="bloodGroup"
                      onChange={handleChange}
                    >
                      <MenuItem value="A(+ve)">A+</MenuItem>
                      <MenuItem value="A(-ve)">A-</MenuItem>
                      <MenuItem value="AB(+ve)">AB+</MenuItem>
                      <MenuItem value="AB(-ve">AB-</MenuItem>
                      <MenuItem value="B(+ve)">B+</MenuItem>
                      <MenuItem value="B(-ve)">B-</MenuItem>
                      <MenuItem value="O(+ve)">O+</MenuItem>
                      <MenuItem value="O(-ve)">O-</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>ID No.</Typography>
                  <TextField
                    label="XXXX"
                    variant="outlined"
                    type="string"
                    fullWidth
                    name="idNo"
                    value={staffData?.idNo}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                ফোন নম্বর/Phone Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="phoneNumBn"
                    value={staffData?.phoneNumBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="phoneNumEn"
                    value={staffData?.phoneNumEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>ই-মেইল/E-mail</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={staffData?.email}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ height: 52.7 }}
                    startIcon={<Photo />}
                    name="photo"
                    onChange={handleAvatarChange}
                  >
                    <input type="file" />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Priority</Typography>
                  <TextField
                    label="Only unique number"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="serial"
                    value={staffData?.serial}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  {newAvatar ? (
                    <img
                      src={newAvatar}
                      alt="Avatar"
                      style={{ width: 80, height: 80 }}
                    />
                  ) : (
                    <img
                      src={staffData?.avatar.url}
                      alt="Avatar"
                      style={{ width: 80, height: 80 }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>

            <Button
              onClick={handleClick}
              sx={{ marginTop: 2, marginBottom: 4 }}
              variant="contained"
              startIcon={<EditIcon color="info" size={20} />}
            >
              {loading ? "Adding..." : "Update Staff"}
            </Button>
          </Box>
          {/* <img src={staffData?.avatar.url} alt="Avatar" />
                <img src={avatar} alt="Avatar" /> */}
        </form>
      )}
    </Box>
  );
};

export default UpdateStaff;
