import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import { CloudUploadRounded, Photo, Send } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toast from "react-hot-toast";
import { convertISOToYYYYMMDD } from "utilities/convertDate";
import DeleteIcon from "@mui/icons-material/Delete";
const UpdateGallery = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [galleryData, setGalleryData] = useState({});
  const [thumbnail, setThumbnail] = useState("");
  const [images, setImages] = useState([]);
  console.log(thumbnail, images);
  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(
          `${server}/gallery/get-single-gallery`,
          {
            params: {
              _id: id,
            },
          }
        );
        //     if(images.length === 0){
        //     for (let i = 0; i < data.gallery.images.length; i++) {
        //         setImages((prev) => [...prev, data.gallery.images[i].url]);
        //     }
        // }
        console.log(images);
        setGalleryData({
          _id: data.gallery._id,
          date: convertISOToYYYYMMDD(data.gallery.date),
          titleBn: data.gallery.title.bn,
          titleEn: data.gallery.title.en,
          descriptionBn: data.gallery.description.bn,
          descriptionEn: data.gallery.description.en,
        });
        setImages(data.gallery.images);
        setThumbnail(data.gallery.thumbnail);
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
   // console.log("aaa", galleryData);
    setLoading(true);

    if (
      galleryData.titleBn === "" ||
      galleryData.titleEn === "" ||
      galleryData.descriptionBn === "" ||
      galleryData.descriptionEn === "" ||
      thumbnail.url === "" ||
      galleryData.date === "" ||
      images.length === 0
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: galleryData._id,

        title: {
          bn: galleryData.titleBn,
          en: galleryData.titleEn,
        },
        description: {
          bn: galleryData.descriptionBn,
          en: galleryData.descriptionEn,
        },

        date: galleryData.date,
        thumbnailChanged: galleryData.thumbnailChanged ? true : false,
        imageChanged: galleryData.imageChanged ? true : false,
        thumbnail,
        images,
      };
      console.log("dataCheck", data);
      axios
        .put(`${server}/gallery/edit-gallery`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };
  const handleThumbnailChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setThumbnail({ ...thumbnail, url: reader.result });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setGalleryData((prevData) => ({
      ...prevData,
      thumbnailChanged: true,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, { url: reader.result }]);
        }
      };
      reader.readAsDataURL(file);
    });
    setGalleryData((prevData) => ({
      ...prevData,
      imageChanged: true,
    }));
  };

  const handleImageDelete = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    console.log(updatedImages, index);
    setImages(updatedImages);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PHOTO GALLERY" subTitle="Managing photo gallery" />
      {galleryData._id && (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Typography
              sx={{
                marginBottom: 1,
              }}
            >
              ENTER THE TITLE
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="titleBn"
                  value={galleryData.titleBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Write in English"
                  variant="outlined"
                  fullWidth
                  name="titleEn"
                  value={galleryData.titleEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              ENTER THE DESCRIPTION
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="descriptionBn"
                  value={galleryData.descriptionBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Write in English"
                  variant="outlined"
                  fullWidth
                  name="descriptionEn"
                  value={galleryData.descriptionEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                marginTop: 3,
              }}
            >
              CHOOSE A DATE
            </Typography>

            <TextField
              sx={{
                width: "100%",
              }}
              id="outlined-basic"
              variant="outlined"
              type="date"
              name="date"
              value={galleryData.date}
              onChange={handleChange}
            />

            <Typography
              sx={{
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              CHANGE A THUMBNAIL PHOTO
            </Typography>
            <Button
              variant="contained"
              component="label"
              sx={{}}
              startIcon={<Photo />}
            >
              <input onChange={handleThumbnailChange} type="file" />
            </Button>
            <div>
              <img
                src={thumbnail.url}
                alt=""
                style={{ width: "80px", height: "80px" }}
              />
            </div>
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
                UPLOAD IMAGES
              </Typography>
              <input
                type="file"
                name=""
                id="upload"
                style={{ display: "none" }}
                multiple
                onChange={handleImageChange}
              />
              <Grid container spacing={2}>
                <Grid item>
                  <label htmlFor="upload">
                    <IconButton component="span">
                      <AddCircleOutlineIcon color="primary" fontSize="large" />
                    </IconButton>
                  </label>
                </Grid>
                {images.map((image, index) => (
                  <Grid item key={image}>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleImageDelete(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 1,
                          color: "#ff0000",
                        }}
                      >
                        <DeleteIcon color="#ff0000" />
                      </IconButton>
                      <img
                        src={image.url}
                        alt=""
                        style={{
                          height: 120,
                          width: 120,
                          objectFit: "cover",
                          margin: 2,
                        }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Button
              sx={{ marginTop: 3 }}
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={<CloudUploadRounded />}
            >
              {loading ? "Uploading..." : "Update"}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default UpdateGallery;
