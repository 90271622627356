import { LoginOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();

	const handleUsername = (e) => {
		setUsername(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	if (!username && !password) {
		navigate('/login');
	}

	const user = {
		username: 'nilmrcbd',
		// password: "@!2023nilmrc"
		// Strong password
		password: 'nilMrc2024@bd'
	};

	const handleLogin = () => {
		if (user.username === username && user.password === password) {
			navigate('/dashboard');
			toast.success('Login Success.');
		} else {
			toast.error('Give correct data to enter the admin dashboard');
		}
	};
	return (
		<div style={{ height: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
					height: '100%',
					width: 500,
					margin: 'auto',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<img
					src="https://www.dashboard.nilmrc.gov.bd/static/media/logo.424debc6943527e22065.png"
					alt=""
				/>
				<Typography variant="h2" color="white" fontWeight={600}>
					Admin Dashboard
				</Typography>
				<TextField
					label="Username"
					variant="outlined"
					name="username"
					fullWidth
					onChange={(e) => handleUsername(e)}
				/>
				<TextField
					label="Password"
					variant="outlined"
					type="password"
					name="password"
					fullWidth
					onChange={(e) => handlePassword(e)}
				/>
				<Button
					onClick={handleLogin}
					variant="contained"
					fullWidth
					sx={{ height: 52.7 }}
					startIcon={<LoginOutlined />}
				>
					Login
				</Button>
			</Box>
		</div>
	);
};

export default Login;
