import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import { useGetAdminsQuery, useGetCustomersQuery } from "state/api";
import axios from "axios";
import { server } from "server";
import { toast } from "react-hot-toast";
import { CloudUploadRounded, Delete, Edit, Photo } from "@mui/icons-material";
import { useParams } from "react-router-dom";
const UpdateVideoGallery = () => {
  const { id } = useParams();
  const [videoGalleryData, setVideoGalleryData] = useState({});
  const [thumbnail, setThumbnail] = useState({});
  const [loading,setLoading]=useState();
  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(`${server}/Videogallery/get-single-video_gallery`, {
          params: {
            _id: id,
          },
        });
        setVideoGalleryData({
          _id: data.videoGallery._id,

          titleBn: data.videoGallery.title.bn,
          titleEn: data.videoGallery.title.en,
          descriptionBn: data.videoGallery.description.bn,
          descriptionEn: data.videoGallery.description.en,
          link: data.videoGallery.link,
        });
        setThumbnail(data.videoGallery.thumbnail);
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
   // console.log("aaa", galleryData);
    setLoading(true);

    if (
      videoGalleryData.titleBn === "" ||
      videoGalleryData.titleEn === "" ||
      videoGalleryData.descriptionBn === "" ||
      videoGalleryData.descriptionEn === "" ||
      thumbnail.url === "" ||
      videoGalleryData.link === "" 
      
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: videoGalleryData._id,

        title: {
          bn: videoGalleryData.titleBn,
          en: videoGalleryData.titleEn,
        },
        description: {
          bn: videoGalleryData.descriptionBn,
          en: videoGalleryData.descriptionEn,
        },

        link: videoGalleryData.link,
        thumbnailChanged: videoGalleryData.thumbnailChanged ? true : false,
       
        thumbnail,
      
      };
      console.log("dataCheck", data);
      axios
        .put(`${server}/Videogallery/edit`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };



  const handleThumbnailChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setThumbnail({ ...thumbnail, url: reader.result });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setVideoGalleryData((prevData) => ({
      ...prevData,
      thumbnailChanged: true,
    }));
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setVideoGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="VIDEO GALLERY" subTitle="Managing video gallery" />
      {videoGalleryData._id && (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Typography
              sx={{
                marginBottom: 1,
              }}
            >
              ENTER THE TITLE
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="titleBn"
                  value={videoGalleryData.titleBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Write in English"
                  variant="outlined"
                  fullWidth
                  name="titleEn"
                  value={videoGalleryData.titleEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              ENTER THE DESCRIPTION
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="descriptionBn"
                  value={videoGalleryData.descriptionBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Write in English"
                  variant="outlined"
                  fullWidth
                  name="descriptionEn"
                  value={videoGalleryData.descriptionEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              CHOOSE A THUMBNAIL PHOTO
            </Typography>
            <Button
              variant="contained"
              component="label"
              sx={{}}
              startIcon={<Photo />}
            >
              <input onChange={handleThumbnailChange} type="file" />
            </Button>
          <div>  <img src={thumbnail.url} alt="thumbnail" style={{width:"80px",height:"80px"}}/></div>
            <Typography
              sx={{
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              ADD A VIDEO LINK
            </Typography>
            <Grid>
              <TextField
                label="Video link"
                variant="outlined"
                fullWidth
                name="link"
                value={videoGalleryData.link}
                onChange={handleChange}
              />
            </Grid>
            <Button
              sx={{ marginTop: 3 }}
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={<CloudUploadRounded />}
            >
              {loading ? "Uploading..." : "Update"}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default UpdateVideoGallery;
