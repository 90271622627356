import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Input, TextField, Typography } from "@mui/material";
import Header from "components/Header";
import {
  CloudUploadRounded,
} from "@mui/icons-material";
import axios from "axios";
import { server } from "server";
import toast from "react-hot-toast";
import ShowNOC from "./ShowNOC";

const NOC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nameBn: "",
    nameEn: "",
    link: "",
  });
  const [data, setData] = useState([]);

  const [textField1Value, setTextField1Value] = useState("");
  const [textField2Value, setTextField2Value] = useState("");
  const [textField3Value, setTextField3Value] = useState("");
  const [textField4Value, setTextField4Value] = useState("");

  const handleTextField1Change = (event) => {
    setTextField1Value(event.target.value);
  };

  const handleTextField2Change = (event) => {
    setTextField2Value(event.target.value);
  };
  const handleTextField3Change = (event) => {
    setTextField3Value(event.target.value);
  };
  const handleTextField4Change = (event) => {
    setTextField4Value(event.target.value);
  };

  function formatDate(inputDate) {
    const options = { year: 'numeric', month: 'long' };
    const date = new Date(inputDate);
    return date.toLocaleDateString(undefined, options);
  }
  const handleClick = () => {
    setLoading(true);
    const data = {
      name: {
        bn: textField1Value,
        en: textField2Value,
      },
      link: textField3Value,
      date: textField4Value,
      date2:formatDate(textField4Value)
    };
    axios
      .post(`${server}/noc/create`, data)
      .then((response) => {
        toast.success("Successfully Added.");
        setTextField1Value("");
        setTextField2Value("");
        setTextField3Value("");
        setTextField4Value("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`${server}/noc/delete-noc/${id}`)
      .then((response) => {
        toast.success("Successfully Deleted.");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };
  useEffect(() => {
    axios
      .get(`${server}/noc/get-noc`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [handleDelete]);
  // console.log(data)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const { nameBn, nameEn, link } = formData;
   
  };

  return (
    <>
    <Box m="1.5rem 2.5rem">
      <Header title="NOC" subTitle="List of NOCs" />
      <form onSubmit={handleSubmit}>
        <Box sx={{ marginTop: 2 }}>
          <Typography sx={{ marginBottom: 1 }}>Enter the name here</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="বাংলাতে লিখুন"
                variant="outlined"
                fullWidth
                name="nameBn"
                type="text"
                value={textField1Value}
                onChange={handleTextField1Change}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Enter the text in English"
                variant="outlined"
                fullWidth
                name="nameEn"
                type="text"
                value={textField2Value}
                onChange={handleTextField2Change}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={6} sx={{ marginTop: 3 }}>
          <Box>
            <Typography
              sx={{
                marginBottom: 1,
              
              }}
            >
              CHOOSE A DATE
            </Typography>

            <TextField
             sx={{
             width:"100%"
            }}
              id="outlined-basic"
              variant="outlined"
              type="date"
              value={textField4Value}
              onChange={handleTextField4Change}
            />
          </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ marginTop: 6.5 }}>
            <Box >
            <TextField
              label="Enter the drive link of NOC"
              variant="outlined"
              fullWidth
              name="link"
              type="text"
              value={textField3Value}
              onChange={handleTextField3Change}
            />
          </Box>
            </Grid>
            </Grid>
         
       
          <br />

          <Button
            type="submit"
            sx={{ marginTop: 1 }}
            onClick={handleClick}
            variant="contained"
            startIcon={<CloudUploadRounded />}
          >
            Update
          </Button>
        </Box>
      </form>
      <ShowNOC nocData={data.noc} handleDelete={handleDelete}/>
    </Box>
    </>
  );
};

export default NOC;
